import React from 'react'

import picSign from '../assets/images/athleticSoul.svg'

class Header extends React.Component {
    render() {
        return (
            <section id="header">
                <div className="inner">
                    <h1> <strong>ATHLETIC SOUL</strong></h1>
                    <p>Il gioco di atletica che tutti aspettavano</p>
                    <ul className="actions">
                        <li><a href="#one" className="button scrolly">Scopri di più</a></li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default Header
